import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../domain/theme';
import { AmbientPlayer } from '../../AmbientPlayer';
import { ParagraphWrapper } from '../ParagraphWrapper';

const AmbientPlayerModuleInner = ({ data, bundle = null }) => {
  const isPublished = !!(
    data.videoEntity?.entity?.isPublished ||
    data.videoEntity?.entity?.mpxStatus === 'Publish succeeded'
  );

  const contentMaxWidth =
    bundle === 'hub_page' && data.videoPlayerSize === 'medium'
      ? '1080px'
      : '1160px';

  const styles = {
    videoInfoStyles: css`
      ${theme.font.family('regularWeb')};
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.1px;
      padding-top: 10px;
    `,
    videoInfoBottomLine: css`
      padding-top: 10px;
      border-bottom: 4px solid #fc0;
      width: 20px;
    `,
    contentWrapper: css`
      margin: 11px auto 0;
      max-width: ${contentMaxWidth};
      margin-bottom: ${theme.spacing('s')};
    `
  };

  return isPublished ? (
    <>
      <AmbientPlayer
        size={data.videoPlayerSize}
        sound={data.videoWithSound}
        videoEntity={data.videoEntity.entity}
        pageType={bundle}
      />
      {data.videoInfo && (
        <div css={styles.contentWrapper}>
          <div css={styles.videoInfoStyles}>{data.videoInfo}</div>
          <div css={styles.videoInfoBottomLine} />
        </div>
      )}
    </>
  ) : (
    <div />
  );
};
AmbientPlayerModuleInner.defaultProps = {
  bundle: null
};

AmbientPlayerModuleInner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  bundle: PropTypes.string
};

export const AmbientPlayerModule = ParagraphWrapper(AmbientPlayerModuleInner);
